@charset "UTF-8";
@import 'main';

// Show page content after all
@import '05-blocks/page-show';

.experience-main {
	padding-bottom: 140px;

	@include media(md) {
		padding-bottom: 120px;
	}

	@include media(sm) {
		padding-bottom: 100px;
	}
}

.experience-component {
	display: grid;
}
