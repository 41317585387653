// stylelint-disable
@font-face {
	font-family: 'NunitoSans';
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/Nunito/NunitoSans-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'NunitoSans';
	font-style: normal;
	font-weight: 600;
	src: url('./fonts/Nunito/NunitoSans-SemiBold.woff2') format('woff2');
}

@font-face {
	font-family: 'NunitoSans';
	font-style: normal;
	font-weight: bold;
	src: url('./fonts/Nunito/NunitoSans-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/Playfair/Playfairdisplayregular.woff2') format('woff2');
}
// stylelint-enable

//New Fonts
$font-main: 'NunitoSans', sans-serif;
$font-alt: 'Playfair Display', serif;
